import React from 'react';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import './AddNeighborhood.css';
import axios from 'axios';
import { RiDeleteBin6Line } from "react-icons/ri";
import TagInput from './TagInput';

function AddNeighborhood({ setSelectedNeighborhood }) {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        locationaddress: '',
        shortdescription: '',
        tags: '',
        longdescription: '',
        links: ''
    });

    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeTags = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.split(' ')
        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        if (files.length + images.length > 10) {
            alert('You can only upload up to 10 images.');
            return;
        }

        const newImages = files.slice(0, 10 - images.length); 
        setImages([...images, ...newImages]);

        // Create previews for the new images
        const newPreviews = newImages.map(file => URL.createObjectURL(file));
        setPreviews([...previews, ...newPreviews]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (images.length < 3) {
            alert('Please upload at least 3 images.');
            return;
        }

        try {
            const formPayload = new FormData();

            images.forEach((image, index) => {
                formPayload.append('images', image);
            });

            const response = await axios.post('/api/addneighborhood', formData);
            const uploadNeighborhoodImages = await axios.post(`/api/addneighborhoodimages/${response.data.id}`, formPayload);
            setSelectedNeighborhood(response.data.id);
            navigate('/');

            alert('Neighborhood added successfully!');
        } catch (error) {
            console.error('There was an error adding the neighborhood!', error);
            alert('Failed to add neighborhood');
        }
    };

    return (
        <div className="AddNeighborhood">
            <div className="BackArrow">
                <IoArrowBackOutline  onClick={() => navigate('/')} size={30} className="Arrow" style={{marginLeft: 'calc(100vw * 0.1)'}}/>
            </div>
            <form className="FormAddNeighborhood" onSubmit={handleSubmit}>
                <div className="Neighborhood">
                    <div className="Images" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div className='FirstImage'>
                            <img className="" style={{maxWidth: 'calc(100vw * 0.45)', maxHeight: 'calc(100vh * 0.54)',borderRadius: '20px'}} src={previews[0]} alt={"Picture 1"}/>
                        </div>  
                        <div className="RightColumn">
                            <div className='SecondImage'>
                                <img className="" style={{maxWidth: 'calc(100vw * 0.22)', maxHeight: 'calc(100vh * 0.26)', borderRadius: '20px'}} src={previews[1]} alt={"Picture 2"}/>
                            </div>
                            <div className='SecondImage'>
                                <img className="" style={{maxWidth: 'calc(100vw * 0.22)', maxHeight: 'calc(100vh * 0.26)', borderRadius: '20px'}} src={previews[2]} alt={"Picture 3"}/>
                            </div>
                        </div>
                    </div>  
                    <div className="BrowseImages">
                        <label htmlFor="images">Upload Images (max 10):</label>
                        <input 
                            type="file" 
                            id="images" 
                            name="images" 
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                            style={{marginLeft: '10px'}}
                        />
                    </div>
                    <div className="ImagesPreview">
                        {previews.map((preview, index) => (
                            <div className="ImageDelete" onClick={() => {
                                setPreviews(previews.filter((_, i) => i !== index));
                                setImages(images.filter((_, i) => i !== index));
                            }}>
                                <img src={preview} className="ImagePreview"/>
                                <RiDeleteBin6Line className="bin" size={20}/>
                            </div>
                        ))}     
                    </div>  
                    <div className="InfoNeighborhood">
                        <h1>
                            <input type="text" className="InputName" placeholder="Name of the neighborhood" name="name" value={formData.name} maxLength={100} onChange={handleChange} required />
                        </h1>
                        <input type="text" className="InputLocation" placeholder="Placename, Country of the neighborhood" name="locationaddress" value={formData.locationaddress} maxLength={100} onChange={handleChange} required />
                        <textarea className="InputShortDescription" placeholder="Short description about the neighborhood (one line)" name="shortdescription" value={formData.shortdescription} maxLength={250} onChange={handleChange} required />    
                        <TagInput name="tags" placeholder={"Add one word tags to describe the neighborhood"} value={formData.tags} onChange={handleChangeTags}/>
                        <textarea className="InputLongDescription value" 
                            placeholder="Long description about the neighborhood (multiple lines)&#10;-&#10;-&#10;-&#10;-&#10;-&#10;-&#10;-&#10;-&#10;-" 
                            name="longdescription" value={formData.longdescription} maxLength={4000} onChange={handleChange} required />
                        <TagInput name="links" placeholder={"Add links of the neighborhood"} style={{color: '#444', backgroundColor: 'lightgrey', fontWeight: 0}} value={formData.tags} onChange={handleChangeTags}/>
                    </div>
                </div>
                <button type="submit" className="submit">Add Neighborhood</button>
                <div className="Footer"/>
            </form> 
        </div>
    );
}

export default AddNeighborhood;
