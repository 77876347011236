import './App.css';
import Neighborhoods from './components/Neighborhoods';
import Neighborhood from './components/Neighborhood';
import AddNeighborhood from './components/AddNeighborhood';
import Login from './components/Login';
import Signup from './components/Signup';
import EmailVerification from './components/EmailVerification';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useState, useEffect} from 'react';
import axios from 'axios';
import { CgProfile } from "react-icons/cg";
import Profile from './components/Profile';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [neighborhoods, setNeighborhoods] = useState([]); 
  const [neighborhoodKeys, setNeighborhoodKeys] = useState(neighborhoods.map(neighborhood => neighborhood.keyneighborhood));

  const fetchNeighborhoods = async () => {
    try {
      const getNeighborhoods = await axios.get('/api/neighborhoods');

      const neighborhoodsWithImages = await Promise.all(
        getNeighborhoods.data.map(async (neighborhood) => {
          try {
            const image = await axios.get(`/api/neighborhoods/${neighborhood.keyneighborhood}/image`);
            
            return {
              image: image.data,
              ...neighborhood,
            };
          } catch (error) {
            console.error('Error fetching image:', error);
            return {
              image: null,
              ...neighborhood,
            };
          }
        })
      );

      setNeighborhoods(neighborhoodsWithImages);
    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
      alert('Error fetching neighborhoods');
    }
  };

  useEffect(() => { 
    fetchNeighborhoods();
  }, []);  // Notice the dependency array with fetchNeighborhoods to avoid warnings

  useEffect(() => {
    setNeighborhoodKeys(neighborhoods.map(neighborhood => neighborhood.keyneighborhood));
  }, [neighborhoods]);

  const handleNeighborhoodClick = (neighborhoodid) => {
    setSelectedNeighborhood(neighborhoodid);
  };

  return (
    <Router>
      <div className="App">
        <div className="Top">
          <div className="TopLeft">
            <img src={'./Communitie.png'} alt="Communitie" style={{width: 'calc(100vh * 0.05)', marginRight: 'calc(100vh * 0.02)', marginLeft: 'calc(100vh * 0.04)'}} />
            {!isLoggedIn && ( 
              <>
                <Link to="/login">
                  <p className="LoginApp">Log in</p>
                </Link>
                <Link to="/signup">
                  <p className="SignupApp">Sign up</p>
                </Link>
              </>
            )}
          </div>
          <div className="TopCenter">
            <h1>Neighborhoods</h1>
            <p>Create, imagine, explore and live in connected spaces. {/* (but first a personal journey)*/}</p>
            <p style={{fontWeight: 'bold'}}>Add as many neighborhoods you know where people live communally, so everyone can start exploring.</p>
            {/* <p>Op zoek naar een ingesteldheid</p>
            <p>Living spaces for internet communities (like Peterson Academy for learning)?</p>
            <p>(Should make database publicly available so anyone can make own version of this, no monopoly, quicker design iteration, better functionality, more matches)</p> */}
          </div>
          <div className="TopRight">
            {
              !isLoggedIn ? 
              (
                <Link to={"/signup"}>
                  <button className="Button">+ Add a neighborhood</button>
                </Link>
              )
              :
              (
                <Link to="/add-neighborhood">
                  <button className="Button">+ Add a neighborhood</button>
                </Link>
              )
            }
            {
              isLoggedIn && (
                <Link to="/profile">
                  <CgProfile size={40} color={"black"} className="ProfileIcon"/>
                </Link>
              )
            }
          </div>
        </div>
        <Routes>
          <Route
            path="/login"
            element={<Login neighborhoodKeys={neighborhoodKeys}/>}
          />
          <Route
            path="/signup"
            element={<Signup neighborhoodKeys={neighborhoodKeys}/>}
          />
          <Route 
            path="/verify/:token" 
            element={<EmailVerification setIsLoggedIn={setIsLoggedIn} setNeighborhoods={setNeighborhoods}/>} 
          />
          <Route
            path="/"
            element={
              selectedNeighborhood ? (
                <Neighborhood
                  setSelectedNeighborhood={setSelectedNeighborhood}
                  neighborhoodid={selectedNeighborhood}
                  isLoggedIn={isLoggedIn}
                />
              ) : (
                <Neighborhoods 
                  neighborhoods={neighborhoods} 
                  setNeighborhoods={setNeighborhoods} 
                  fetchNeighborhoods={fetchNeighborhoods} 
                  onNeighborhoodClick={handleNeighborhoodClick} 
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
              )
            }
          />
          <Route
            path="/add-neighborhood"
            element={<AddNeighborhood setSelectedNeighborhood={setSelectedNeighborhood}/>}
          />
          <Route 
            path="/profile"
            element={<Profile onNeighborhoodClick={handleNeighborhoodClick} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
