import React from "react";
import './Signup.css';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import axios from 'axios';
import { useState } from 'react';

function Signup( { neighborhoodKeys }) {    
    const navigate = useNavigate();

    const [formData, setFormData] = useState();

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail(formData.email)) {
            alert('Invalid email address format');
            return;
        }

        try {
            const updatedFormData = {
                ...formData,
                neighborhoodKeys: neighborhoodKeys
            };

            const response = await axios.post('/api/auth/signup', updatedFormData);
            alert('Go to your email to login!');
        } catch (error) {
            console.error('There was an error signing in', error);
            alert('There was an error singing in.  ' + error.response.data.message);
        }
    }; 

    return (
        <div>
            <div className="BackArrow">
                <IoArrowBackOutline  onClick={() => navigate('/')} size={30} className="Arrow" style={{marginLeft: 'calc(100vw * 0.18)'}}/>
            </div>
            <div className="Signup">
                <h1>Signup</h1>
                <form>
                    <div className="Form">
                        <div className="FirstRow">
                            <div className="Firstname">
                                <label>First name</label>
                                <input id="firstName" className="FormInput" name="firstname" onChange={handleChange} required maxLength="255" type="text"/>
                            </div>
                            <div className="Lastname">
                                <label>Last name</label>
                                <input id="lastName" className="FormInput" name="lastname" onChange={handleChange} required maxLength="255" type="text"/>
                            </div>
                        </div>
                        <div className="SecondRow">
                            <div className="Email">
                                <label>Email</label>
                                <input id="email" className="FormInput" name="email" onChange={handleChange} required maxLength="255" autoComplete="email" type="email"/>
                            </div>
                            <div className="DateOfBirth">
                                <label>Date of birth</label>
                                <input id="birthDate" className="FormInput" name="dateOfBirth" onChange={handleChange} min="1900-01-01" max="2006-08-23" required pattern="\d{4}-\d{2}-\d{2}" placeholder="dd-mm-yyyy" type="date"/>
                            </div>
                        </div>
                        <button type="submit" className="SignUpButton" onClick={handleSubmit}>Sign Up</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Signup;