import React from 'react';
import './NeighborhoodImageOverlay.css';

function NeighborhoodImageOverlay({ images, onClose }) {
    return (
        <div className="Overlay">
          <div className="OverlayContent">
            <button className="CloseButton" onClick={onClose}>Close</button>

            <div className="ImageList">
              {images.map((image, index) => (
                <div key={index} className="ImageContainer">
                  <img src={image} alt={`Image ${index}`} className="OverlayImage" />
                </div>
              ))}
            </div>
          </div>
        </div>
    )
}

export default NeighborhoodImageOverlay;