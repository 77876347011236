import React from "react";
import { useState, useRef } from "react";
import "./TagInput.css";

function TagInput({ name, placeholder, style, value, onChange}) {

    // Tag input
    const [inputValue, setInputValue] = useState(value); //onchange nog doen
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const handleInput = (e) => {
        const value = e.target.innerText;
        setInputValue(value);
        // Show placeholder if the input is empty or contains only empty strings
        const words = value.split(' ').filter((word) => word.trim() !== '');
        setShowPlaceholder(words.length === 0);
    };

    const handleFocus = () => {
        setIsFocused(true);
        // setShowPlaceholder(false);
    };

    const handleBlur = () => {
        setIsFocused(false);
        const words = inputValue.split(' ').filter((word) => word.trim() !== '');
        setShowPlaceholder(words.length === 0);
    };

    // Split words by spaces
    const words = inputValue.split(' ').filter((word) => word.trim() !== '');

    return (
        <div className="input-container" onClick={() => inputRef.current.focus()} >
            <div 
                className={`styled-input ${isFocused ? 'focused' : ''}`} 
                onClick={() => inputRef.current.focus()}
                ref={inputRef}
            >
                {words.map((word, index) => (
                    word && (
                        <span key={index} className="word" style={style}>
                            {word}
                        </span>
                    )
                ))}
                {isFocused && <span className="caret"></span>}
                {showPlaceholder && !inputValue && (
                    <div className="placeholder">{placeholder}</div>
                )}
            </div>
            <input
                type="text"
                name={name}
                maxLength={500}
                value={inputValue}
                onChange={(e) => {setInputValue(e.target.value); onChange(e);}}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onInput={handleInput}
                ref={inputRef}
                className="hidden-input"
            />
        </div>
    );
}

export default TagInput;