import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import NeighborhoodItem from "./NeighborhoodItem";

function Profile( { onNeighborhoodClick, isLoggedIn, setIsLoggedIn }) {
    const navigate = useNavigate();

    const [savedNeighborhoods, setSavedNeighborhoods] = useState();

    const fetchSavedNeighborhoods = async () => {
        if(isLoggedIn){
          try {
            const token = localStorage.getItem('token');
            const savedNeighborhoods = await axios.get('/api/neighborhoodssavedfull', {
              headers: {
                Authorization: `Bearer ${token}` // Add token in the Authorization header
              }
            });

            const savedNeighborhoodsWithImages = await Promise.all(
                savedNeighborhoods.data.map(async (neighborhood) => {
                  try {
                    const image = await axios.get(`/api/neighborhoods/${neighborhood.keyneighborhood}/image`);
                    
                    return {
                      image: image.data,
                      ...neighborhood,
                    };
                  } catch (error) {
                    console.error('Error fetching image:', error);
                    return {
                      image: null,
                      ...neighborhood,
                    };
                  }
                })
              );
        
            setSavedNeighborhoods(savedNeighborhoodsWithImages);
    
          } catch (error) {
            if (error.response && error.response.status === 401) {
              setIsLoggedIn(false);
              alert('Session expired. Please log in again.');
              localStorage.removeItem('token'); 
              navigate('/login');
            } else {
              alert('Error fetching saved neighborhoods');
            }
          }
        }
    };

    useEffect(() => {
        fetchSavedNeighborhoods();
    }, []);

    return (
        <div>
            <div className="BackArrow">
                <IoArrowBackOutline  onClick={() => navigate('/')} size={30} className="Arrow" style={{marginLeft: 'calc(100vw * 0.1)'}}/>
            </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: 'calc(100vw * 0.15)', marginRight: 'calc(100vw * 0.15)' }}>
                    <h1>Profile</h1>
                    <h2>Saved Neighborhoods</h2>
                </div>
                <div className="Neighborhoods" style={{ marginLeft: 'calc(100vw / 10)', marginRight: 'calc(100vw / 10)' }}>
                    {savedNeighborhoods?.map((neighborhood) => (
                        <NeighborhoodItem key={neighborhood.keyneighborhood} neighborhood={neighborhood} onNeighborhoodClick={onNeighborhoodClick} savedNeighborhoods={[]} profile={true}/>
                    ))}
                </div>
            
        </div>
    );
}

export default Profile;