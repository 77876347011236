import React, {useState, useEffect} from 'react';
import './Neighborhood.css';
import { IoArrowBackOutline } from "react-icons/io5";
import axios from 'axios';
import NeighborhoodImageOverlay from './NeighborhoodImageOverlay';

function Neighborhood({ setSelectedNeighborhood, neighborhoodid }) {

    const [neighborhood, setNeighborhood] = useState(null);
    const [isOverlayVisible, setOverlayVisible] = useState(false);

    useEffect(() => {
        const fetchNeighborhood = async () => {
            try {
                const neighborhood = await axios.get(`/api/neighborhoods/${neighborhoodid}`);
                setNeighborhood(neighborhood.data);
            }
            catch (error) {
                alert('Error fetching neighborhood');
            }
        };

        fetchNeighborhood();
    }, []);

    const openOverlay = () => {
        setOverlayVisible(true);
      };
    
    const closeOverlay = () => {
        setOverlayVisible(false);
    };


    console.log(neighborhood?.longdescription);

  return (
    <>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>
                <IoArrowBackOutline onClick={() => setSelectedNeighborhood(null)} size={30} className="Arrow" style={{marginLeft: 'calc(100vw * 0.1)'}}/>
            </div>
        </div>
        <div className="Neighborhood">
            <div className="NeighborhoodImages" onClick={openOverlay} style={{display: 'flex', justifyContent: 'center'}}>
                <img src={neighborhood?.images[0]} alt={neighborhood?.name} style={{ width: 'calc(100vw * 0.45)', maxHeight: 'calc(100vh * 0.65)', margin: 'calc(100vw * 0.01)', borderRadius: '20px', objectFit: 'cover' }}/>  
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <img src={neighborhood?.images[1]} alt={neighborhood?.name} style={{ width: 'calc(100vw * 0.22)', maxHeight: 'calc(100vh * 0.65)', margin: 'calc(100vw * 0.01)', borderRadius: '20px', objectFit: 'cover' }}/>
                    <img src={neighborhood?.images[2]} alt={neighborhood?.name} style={{ width: 'calc(100vw * 0.22)', maxHeight: 'calc(100vh * 0.65)', margin: 'calc(100vw * 0.01)', borderRadius: '20px', objectFit: 'cover' }}/>
                </div>
            </div>  
            <div className="InfoNeighborhood">
                <h1>{neighborhood?.name}</h1>
                <p className="Location">{neighborhood?.locationaddress}</p>
                <p className="Description">{neighborhood?.shortdescription}</p>
                <div className="Types">
                    {neighborhood?.tags.map((type) => (
                        <p className="Type">{type}</p>
                    ))}
                </div>
                
                <p className="Description">
                    {neighborhood?.longdescription?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </p>
                {neighborhood?.links.map((link) => (
                    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                ))}
                <h2>Community chat</h2>
                {/* <h2>Journeys / Play / Activities</h2>
                <h2>Sleep</h2>
                <h2>Work</h2> */}
            </div>
            <div >
                
            </div>
        </div>
        <div className="Footer"/>
        {isOverlayVisible && (<NeighborhoodImageOverlay images={neighborhood.images} onClose={closeOverlay} />)}
    </>
  );
}

export default Neighborhood;
